import React from 'react';
import { FlatList, View } from 'react-native';

import { ITheme, useTheme } from '@rugby-au/theme';

import { CardItem, CardItemProps } from './CardItem';
import { getCardListStyles } from './styles';
import { CardContent, CardContentProps } from './CardContent';

export interface CardListProps {
  /** Card onPress function */
  onPress?: (item: any) => void;
  data: any[];
  appearance?: ITheme;
  cardItemListFooter?: CardItemProps;
  cardContentListFooter?: CardContentProps;
}

export const CardList = ({ onPress, data, appearance = 'primary', cardItemListFooter, cardContentListFooter }: CardListProps) => {
  const { colorPairs, spacing } = useTheme();
  // console.log(`GeneralCard.tsx line 40 - imageProps `, imageProps)

  const listSeparator = () => {
    return (
      <View
        style={{
          height: 1,
          backgroundColor: colorPairs[appearance].background.mid,
        }}
      />
    );
  };

  const styles = getCardListStyles({
    colors: colorPairs[appearance],
    spacing,
    // size: sizes[size] ?? sizes.large,
  });

  return (
    <FlatList
      style={styles.list}
      data={data}
      renderItem={({ item }) => (
        <CardItem
          // title={'primary'}
          addBorder={true}
          disabled={false}
          appearance={'light'}
          iconRight={'rightArrow'}
          onPress={() => {
            if (onPress) {
              onPress(item);
            }
          }}
          avatarProps={{
            name: `${item.firstname} ${item.lastname}`,
            size: 'large',
            appearance: 'highlight',
            addBorder: false,
            image: {
              url: item.headshot,
              alt: `${item.firstname} ${item.lastname}`,
            },
          }}>
          <CardContent title={item.title} description={item.description} info={item.info} />
        </CardItem>
      )}
      ItemSeparatorComponent={listSeparator}
      keyExtractor={item => item.name}
      ListFooterComponent={getFooter(cardItemListFooter, cardContentListFooter)}
      ListFooterComponentStyle={styles.listFooter}
    />
  );
};

const getFooter = (cardItemListFooter: CardItemProps | undefined, cardContentListFooter: CardContentProps | undefined) => {
  return (
    <View style={{ borderTopWidth: 1 }}>
      {cardItemListFooter && <CardItem {...cardItemListFooter}>{cardContentListFooter && <CardContent {...cardContentListFooter} />}</CardItem>}
    </View>
  );
};

import { useEffect } from 'react';

import { useAppConfig } from '@rugby-au/app-config';
import { getItem, getUser, setItem } from '@rugby-au/business';

import { CheckSignInProps } from './CheckSignInLayout';

export const useCheckSignInLayout = ({ redirectOnLogin, triggerNext }: CheckSignInProps) => {
  const { setNavigation, setIsLogged } = useAppConfig();

  //Check if user already logged in, Since AsyncStorage use windows variable we need to make sure that this run after the page is build
  useEffect(() => {
    getUser()
      .then(async (user: any) => {
        if (user && user?.userId && !user?.error) {
          setIsLogged(true);

          if (redirectOnLogin) {
            if (triggerNext) {
              await triggerNext();
            } else {
              let navigationPath = '/';
              let redirectTo = await getItem('redirectTo');
              if (redirectTo) {
                navigationPath = redirectTo;
                setItem('redirectTo', null);
              }
              // console.log('CheckSignInLayout.tsx line 42 - navifationPath ', navifationPath);
              setNavigation && setNavigation(navigationPath);
            }
          }
        } else {
          const loginUrl = `${process.env.NEXT_PUBLIC_AUTH_URL}login?clientId=memberships&codeChallenge=&redirectPath=/`;
          setNavigation && setNavigation(loginUrl);
        }
      })
      .catch((error: any) => {
        console.error('useCheckSignInLayout.tsx line 40 - error ', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {};
};

import React, { useRef } from 'react';
import { View } from 'react-native';

import { addEncodedParams, getItem } from '@rugby-au/business';
import { Container } from '@rugby-au/container';
import { Form, FormRefProps } from '@rugby-au/form';
import { useTheme } from '@rugby-au/theme';
import { forgotPasswordFormConfig } from './forgotPasswordFormConfig';
import { useAppConfig } from '@rugby-au/app-config';
import { useRouter } from 'next/router';

export const ForgotPasswordLayout = () => {
  const { setNavigation, setNotification } = useAppConfig();
  const formRef = useRef<FormRefProps>(null);
  const { breakpoints, spacing } = useTheme();

  const params = useRouter();

  const cleanSubmitData = async (submitData: any) => {
    const client = await getItem('client');
    const query = params.query;
    //add any param query to the submitData in a dymamic way
    const aditionalQueryParams: { [key: string]: any } = {};
    for (const key in query) {
      if (Object.prototype.hasOwnProperty.call(query, key) && !['clientId', 'codeChallenge', 'redirectPath'].includes(key)) {
        aditionalQueryParams[key] = query[key];
      }
    }
    if (query.redirectUrl) {
      const redirectUrl = new URL(decodeURIComponent(query.redirectUrl as string));
      query.redirectPath = redirectUrl.pathname + redirectUrl.search;
    }
    submitData.redirectPath = query.redirectPath;
    if (!submitData.clientId) {
      submitData.clientId = client?.username;
    }
    if (query?.codeChallenge) {
      submitData.codeChallenge = query.codeChallenge;
    }
    // if (!submitData.redirectUrl) {
    //   submitData.redirectUrl = client?.redirectUrl;
    // }
    submitData = { ...submitData, aditionalQueryParams };

    return submitData;
  };

  const onFinished = async (result: any) => {
    if (result?.isValid) {
      const message =
        result.submitData.type === 'email'
          ? 'If the account exists, a reset password email will send to your email address'
          : 'If the account exists, a reset password sms will send to your mobile';

      setNotification({ severity: 'success', message });
      setTimeout(() => {
        setNavigation && setNavigation(addEncodedParams('/login', params.query));
      }, 3000);
    }
    return result;
  };

  return (
    <Container>
      <View style={{ flexDirection: 'column', flex: 1, width: '100%', maxWidth: breakpoints.medium, margin: 'auto', alignItems: 'center', paddingTop: spacing['4xlarge'] }}>
        <View style={{ width: '100%' }}>
          <Form formRef={formRef} formConfig={forgotPasswordFormConfig} cleanSubmitData={cleanSubmitData} onFinished={onFinished} showOnSubmitError={true} />
        </View>
      </View>
    </Container>
  );
};

import { getClientUIConfig } from '@rugby-au/business';
import { IColorScale, getThemeColorForSite } from '@rugby-au/theme';

/**
 * This method is used to get the client colors for the logo for the client id
 * Runs on the server side
 * @param param0
 * @returns {Promise<{clientColors: {primary: IColorScale; secondary: IColorScale}; logo: string}>}
 */
export const getClientConfig = async ({ clientId, defaultColors }: { clientId?: string; defaultColors: { primary: IColorScale; secondary: IColorScale } }) => {
  try {
    let clientColors;
    let logo = '';
    if (clientId) {
      // option get client colors for client id from api
      const response = await getClientUIConfig({ username: clientId });
      if ('error' in response) {
        clientColors = defaultColors;
      }
      if ('primaryColour' in response && response && response.primaryColour && response.secondaryColour) {
        clientColors = getThemeColorForSite({ primary: response.primaryColour, secondary: response.primaryColour }) ?? defaultColors;
      }
      if ('logo' in response && response?.logo) {
        logo = response.logo;
      }
    }
    if (!clientColors || Object.keys(clientColors).length === 0) {
      clientColors = defaultColors;
    }
    return { clientColors, logo };
  } catch (error) {
    console.log('Error in getClientColors ~ error:', error);
    return { clientColors: defaultColors, logo: '' };
  }
};

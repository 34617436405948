import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
// import Animated, { useSharedValue, useAnimatedStyle, Easing, withTiming, withSequence } from 'react-native-reanimated';
import { useAppConfig } from '@rugby-au/app-config';

import { LayoutRefProps, MultiFormConfigProps } from './types';
import { ButtonProps } from '@rugby-au/button';
import { ITheme } from '@rugby-au/theme';
import { View } from 'react-native';

export const useMultiForm = ({ config, metaData, appearance = 'primary' }: { config: MultiFormConfigProps; metaData?: { [key: string]: any }; appearance?: ITheme }) => {
  // Get an array of the steps from the config
  const steps = useMemo(() => {
    return Object.keys(config).map(key => key);
  }, [config]);

  const appConfig = useAppConfig();

  // Progress of the multi page form
  const [currentStepIndex, setCurrentStepIndex] = useState(metaData && steps?.indexOf(metaData.initLayout) >= 0 ? steps.indexOf(metaData.initLayout) : 0);
  //This is to determine animation direction
  // const [historyStepIndex, setHistoryStepIndex] = useState(currentStepIndex);
  // console.log('useMultiForm.tsx line 19 - currentStepIndex ', currentStepIndex);
  // Hold the current layout config
  const [currentLayout, setCurrentLayout] = useState(config[steps[currentStepIndex]]);
  // console.log('useMultiForm.tsx line 22 - currentLayout ', currentLayout);
  // Keeps the history of the layouts
  const [layoutHistory, setLayoutHistory] = useState<string[]>([]);
  // Hold form data for all the layouts
  const [multiFormData, setMultiFormData] = useState({ __meta: metaData } ?? {});
  // Ref to communicate with the current layout
  const layoutRef = useRef<LayoutRefProps>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [nextButtonProps, setNextButtonProps] = useState<ButtonProps>({ disabled: false });
  const [prevButtonProps, setPrevButtonProps] = useState<ButtonProps>({ disabled: false });

  useEffect(() => {
    setMultiFormData(prev => ({ ...prev, __meta: { ...metaData, appearance } }));
  }, [metaData, appearance]);
  const handleNext = async () => {
    if (layoutRef.current) {
      // Check whether current layout allows to go to next layout and get the next layout name
      setIsLoading(true);
      const { isValid } = await layoutRef.current.beforeNext();
      setIsLoading(false);
      if (isValid) {
        await triggerNext();
      }
    } else {
      await triggerNext();
    }
  };
  const triggerNext = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true);
    }
    try {
      resetButtonProps();
      if (currentLayout.isAddToHistory === true || currentLayout.isAddToHistory === undefined) {
        // Set current layout as history
        setLayoutHistory(prev => [...prev, steps[currentStepIndex]]);
      }
      // get layout index
      const nextLayout = await currentLayout.nextLayout(appConfig, multiFormData);
      if (nextLayout && !currentLayout.isEnd) {
        const _currentStep = steps.indexOf(nextLayout);
        setCurrentStepIndex(_currentStep <= steps.length - 1 ? _currentStep : steps.length - 1);
        setCurrentLayout(config[nextLayout]);
        resetButtonProps();
      }
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  }, [appConfig, config, currentLayout, currentStepIndex, isLoading, multiFormData, steps]);

  const handlePrev = async () => {
    let isValid = false;
    if (layoutRef.current) {
      const beforeNextResponse = await layoutRef.current.beforeBack();
      isValid = beforeNextResponse.isValid;
    } else {
      isValid = true;
    }
    // checks whether current layout allows to go to previous layout
    if (isValid && layoutHistory.length > 0) {
      // Get the last layout from history
      // const lastLayout = layoutHistory[layoutHistory.length - 1];
      let lastLayout: string | null | undefined = '';
      const _layoutHistory = [...layoutHistory];
      if (currentLayout.backLayout) {
        lastLayout = await currentLayout.backLayout(appConfig, multiFormData);
      } else {
        lastLayout = _layoutHistory.pop();
      }
      // const _layoutHistory = [...layoutHistory];
      // const lastLayout = _layoutHistory.pop();
      if (lastLayout) {
        setLayoutHistory(_layoutHistory);
        const _currentStep = steps.indexOf(lastLayout);
        setCurrentStepIndex(_currentStep >= 0 ? _currentStep : 0);
        setCurrentLayout(config[lastLayout]);
        resetButtonProps();
      }
    }
  };

  const triggerToPrev = useCallback(
    ({ layoutKey, forceStep, clearHistory }: { layoutKey: string; forceStep?: boolean; clearHistory?: boolean }) => {
      setIsLoading(true);

      try {
        if (layoutKey) {
          // Get the layoutKey from history
          const indexOfLayoutKey = layoutHistory.indexOf(layoutKey);

          if (indexOfLayoutKey >= 0) {
            const _layoutHistory = layoutHistory.slice(0, indexOfLayoutKey);
            setLayoutHistory(_layoutHistory);
            const _currentStep = steps.indexOf(layoutKey);
            setCurrentStepIndex(_currentStep >= 0 ? _currentStep : 0);
            setCurrentLayout(config[layoutKey]);
            resetButtonProps();
          } else if (steps.indexOf(layoutKey) >= 0 && forceStep) {
            // Requested key is not found in history, so we will go to that key directly. If the layout is available in steps, we will go to that layout
            const _currentStep = steps.indexOf(layoutKey);
            setCurrentStepIndex(_currentStep <= steps.length - 1 ? _currentStep : steps.length - 1);
            setCurrentLayout(config[layoutKey]);
            resetButtonProps();
          }

          if (clearHistory) {
            setLayoutHistory([]);
          }
        }
      } catch (e) {
        console.log(e);
      }
      setIsLoading(false);
    },
    [config, layoutHistory, steps],
  );

  useEffect(() => {
    if (appConfig && appConfig.multiFormLayout && steps[currentStepIndex] !== appConfig.multiFormLayout) {
      //trigger layout change and reset history
      triggerToPrev && triggerToPrev({ layoutKey: appConfig.multiFormLayout, forceStep: true, clearHistory: true });
      appConfig.setMultiFormLayout(null);
    } else if (appConfig.multiFormLayout) {
      appConfig.setMultiFormLayout(null);
    }
  }, [appConfig, steps, currentStepIndex, triggerToPrev]);

  // const timerRef = useRef<any>(null);

  // Need to fix nextjs ssr issue with animation
  // const offset = useSharedValue(0);
  // const opacity = useSharedValue(0);

  // const animatedStyles = useAnimatedStyle(() => {
  //   return {
  //     transform: [{ translateX: offset.value }],
  //     opacity: opacity.value,
  //   };
  // }, [offset, opacity]);
  // useEffect(() => {
  //   if (timerRef.current) {
  //     clearTimeout(timerRef.current);
  //   }

  //   opacity.value = 0;
  //   timerRef.current = setTimeout(() => {
  //     offset.value = withSequence(
  //       withTiming((currentStepIndex >= historyStepIndex ? 1 : -1) * 200, {
  //         duration: 0,
  //         easing: Easing.out(Easing.exp),
  //       }),
  //       withTiming(0, {
  //         duration: 500,
  //         easing: Easing.out(Easing.exp),
  //       }),
  //     );
  //     opacity.value = withSequence(
  //       // withTiming(0, {
  //       //   duration: 0,
  //       //   easing: Easing.out(Easing.exp),
  //       // }),
  //       withTiming(1, {
  //         duration: 100,
  //         easing: Easing.out(Easing.exp),
  //       }),
  //     );
  //   }, 200);
  //   setHistoryStepIndex(currentStepIndex);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentStepIndex]);

  const getLayout = () => {
    if (currentLayout && currentLayout.component) {
      return (
        // <Animated.View style={[{ opacity: 0 }, animatedStyles]}>
        <View>
          <currentLayout.component
            layoutRef={layoutRef}
            layoutKey={steps[currentStepIndex]}
            nextButtonProps={setNextButtonProps}
            prevButtonProps={setPrevButtonProps}
            triggerNext={triggerNext}
            triggerToPrev={triggerToPrev}
            {...currentLayout.props}
          />
          {/* </Animated.View> */}
        </View>
      );
    } else {
      return <></>;
    }
  };

  const resetButtonProps = () => {
    setNextButtonProps({});
    setPrevButtonProps({});
  };

  return { getLayout, handleNext, handlePrev, currentStepIndex, steps, multiFormData, setMultiFormData, nextButtonProps, prevButtonProps, isLoading };
};

import { useAppConfig } from '@rugby-au/app-config';
import { loadBasicAuthHeaders, setItem } from '@rugby-au/business';
import { FormRefProps } from '@rugby-au/form';
import { MultiFormLayoutProps, useMultiFormProvider } from '@rugby-au/multi-form';
import React, { useEffect, useRef } from 'react';
import { signInFormConfig } from '../../SignIn/signInFormConfig';
import { Password } from './Password';

export const PasswordLayout = ({ layoutRef, layoutKey }: MultiFormLayoutProps) => {
  const { setNavigation, setIsLogged } = useAppConfig();
  const formRef = useRef<FormRefProps>(null);
  const [error, setError] = React.useState<string | null>(null);
  const { multiFormData } = useMultiFormProvider();

  signInFormConfig.fields = signInFormConfig.fields.filter((field: any) => field.key !== 'email');
  delete signInFormConfig?.meta?.submitButton;
  const getHeaders = (data: any) => {
    return loadBasicAuthHeaders(`${multiFormData.__meta.existingUser.email}:${data.password}`);
  };

  useEffect(() => {
    const beforeNext = async () => {
      const result = await formRef.current?.onSubmit();
      if (result?.isValid && !result?.response?.error && result?.response.userId) {
        await setItem('user', result.response);
        setIsLogged(true);
        setNavigation && setNavigation('/');
      } else {
        setError(result?.response?.error?.originalError?.status || result?.response?.error?.message);
      }
      return { isValid: false, message: result?.response?.error };
    };

    const beforeBack = async () => {
      return { isValid: true };
    };

    if (layoutRef && layoutKey) {
      layoutRef.current = {
        beforeNext,
        beforeBack,
      };
    }
  }, [layoutKey, layoutRef, setIsLogged, setNavigation]);

  return <Password formRef={formRef} formConfig={signInFormConfig} getHeaders={getHeaders} error={error} />;
};

import { getNationals } from '@rugby-au/business/src/config';

/**
 *  Method to populate national name
 * @param
 */
export const addNational = async ({ obj, nationalId }: { obj: any; nationalId: any }) => {
  let nationals = await getNationals();
  // if nationalId is an object, we assume that the nationalId is the national
  if (typeof nationalId === 'object') {
    nationalId = nationalId._id;
  }
  let currentNational: any = nationals.find((n: any) => n._id === nationalId);
  obj.nationals = { [currentNational?._id]: { _id: currentNational?._id, name: currentNational?.name } };
  return obj;
};

import React, { useRef, useState } from 'react';
import { View } from 'react-native';
import { useAppConfig } from '@rugby-au/app-config';
import { Form, FormRefProps } from '@rugby-au/form';
import { Text } from '@rugby-au/commons';
import { useTheme } from '@rugby-au/theme';

import { resetPasswordFormConfig } from './resetPasswordFormConfig';
import { getStyles } from './styles';
import { useRouter } from 'next/router';
import { addEncodedParams } from '@rugby-au/business';

export const ResetPasswordLayout = ({ token }: { token: string | undefined }) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const passwordError =
    'Your password is not strong enough. Password needs to have a minimum of 10 characters with at least 1 uppercase letter, 1 lowercase letter and 1 number and 1 special character';

  const { setNavigation } = useAppConfig();
  const formRef = useRef<FormRefProps>(null);
  // const getHeaders = async (submitData: any) => {
  //   return { userId: submitData.password };
  // };

  const cleanSubmitData = async (submitData: any) => {
    delete submitData.confirmPassword;
    return submitData;
  };

  const formExtraValidation = async (submitData: any) => {
    if (submitData.password !== submitData.confirmPassword) {
      return { message: 'Passwords do not match, please try again', isValid: false };
    }

    const re = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\*\\#\\.\\!\\@\\\'\\"\\$\\%\\^\\&\\(\\)\\{\\}\\[\\]\\:\\;\\<\\>\\,\\?\\/\\~\\_\\+\\-\\=\\|]).{10,}$');
    if (!re.test(submitData.password)) {
      return { message: passwordError, isValid: false };
    }
    return { message: 'success', isValid: true };
  };

  const modifySubmitPath = (path: string) => {
    return `${path}${token}`;
  };

  const params = useRouter();

  const onFinished = async (result: any) => {
    if (result.isValid) {
      setNavigation && setNavigation(addEncodedParams('/login', params.query));
    } else {
      setError(result?.response?.error?.originalError ? result?.response?.error?.originalError?.status : result?.response?.error); //Todo move this logic about "error" to the Form component and make it more generic if original error does not exists then use just error
    }
    return { message: 'success', isValid: true };
  };

  const { colors, spacing, breakpoints } = useTheme();
  const styles = getStyles({ colors, spacing });
  return (
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: 'column', flex: 1, width: '100%', maxWidth: breakpoints.medium, margin: 'auto', alignItems: 'center', paddingTop: spacing['4xlarge'] }}>
        <View style={{ width: '100%' }}>
          <Form
            formRef={formRef}
            formConfig={resetPasswordFormConfig}
            onFinished={onFinished}
            extraValidation={formExtraValidation}
            modifySubmitPath={modifySubmitPath}
            // getHeaders={getHeaders}
            cleanSubmitData={cleanSubmitData}
            showOnSubmitError={true}
          />
        </View>
        <Text style={styles.errorContainer}>
          Password needs to have a minimum of 10 characters with at least 1 uppercase letter, 1 lowercase letter and 1 number and 1 special character{' '}
        </Text>
        {error && (
          <View style={styles.errorContainer}>
            <Text style={styles.errorText}>{error}</Text>
          </View>
        )}
      </View>
    </View>
  );
};
